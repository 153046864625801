import React, {useEffect, useState} from 'react';
import Graph from './Graph';
import { Select, Layout, Row, Col, PageHeader } from 'antd';

import 'antd/dist/antd.css';
import './App.css';

const { Option } = Select;

const isIdentifier = (index) => index === 0;

const normalizeData = (csv) => {
  let years;
  const data = {}
  const cycle = 8;
  let lineNumber = 0;
  let currentProduct = null;
  csv.split('\n').forEach(line => {
    const splitLine = line.split(',');
    if (lineNumber === 0) {
      const [_, ..._years] = splitLine;
      years = _years;
    } else {
      let valueType;
      splitLine.forEach((value, index) => {
        if (isIdentifier(index)) {
          if (lineNumber % cycle === 1) {
            currentProduct = value;
            data[currentProduct] = {};
            valueType = 'origin';
          } else {
            valueType = value;
          }
        } else {
          if (!data[currentProduct][valueType]) {
            data[currentProduct][valueType] = [];
          }
          data[currentProduct][valueType].push(value)
        }
      })
    }

    lineNumber += 1;
  })
  return {data, label: years};
}

function App() {
  const [data, setData] = useState(null);
  const [label, setLabel] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  useEffect(() => {
    fetch('/data.csv')
    .then(resp => resp.text())
    .then(result => {
      const normedData = normalizeData(result);
      setData(normedData.data);
      setLabel(normedData.label)
    })

  }, []);

  const handleChange = (value) => {
    setSelectedProduct(value)
  }


  return (
    <>
      <PageHeader
        className="site-page-header"
        title="TYC 堤維西交通工業公司需求預測視覺化"
        extra={[
          <span>&copy;Copyright <a href="https://ctxhou.com?utm_source=tyc-visualization" target="_blank" rel="noopener noreferrer">侯貞泰</a> 版權所有 </span>
        ]}
      />
      <Layout className="container">
        {data ?
          <>
            <Select
              mode="multiple"
              style={{ width: '100%', paddingBottom: '10px' }}
              placeholder="請選擇一個產品"
              defaultValue={selectedProduct}
              onChange={handleChange}
            >
              {Object.keys(data).map(product => {
                return <Option key={product}>{product}</Option>
              })}
            </Select>
            <Row>
              {selectedProduct.map(product => (
                <Col key={product} span={12} className="col">
                  <h2>{product}</h2>
                  <Graph data={data[product]} label={label} />
                </Col>
              ))}
            </Row>
          </>
        : null}
      </Layout>
    </>
  );
}

export default App;
