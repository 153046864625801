import React, {useState} from 'react';

import {Line} from 'react-chartjs-2';

const types = {
    origin: {
        name: '原始資料',
        color: '#FFBF5A'
    },
    'one-month-predict': {
        name:'預測-1個月平均',
        color: '#8884d8'
    },
    'predict-0.9': {
        name:'預測值 0.9',
        color: '#82ca9d',
    },
    'predict-0.6': {
        name:'預測值 0.6',
        color: '#61D7D7',
    },
    'predict-0.3': {
        name:'預測值 0.3',
        color: '#344A81',
    },
    'MA3': {
        name:'MA3',
        color: '#8C1518',
    },
    'regression': {
        name:'複迴歸',
        color: '#22293D',
    },
    'average': {
        name:'加權平均',
        color: '#0083F9',
    },
}

const genConfig = (data, analysis) => {
    return {
        label: types[analysis].name,
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: types[analysis].color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: types[analysis].color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: types[analysis].color,
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data
    }
}

const Graph = ({data, label}) => {
    const dataConfig = {
        labels: label,
        datasets: Object.keys(data).map(analysis => genConfig(data[analysis], analysis))
    };

    return (
        <Line data={dataConfig} options={{tooltips: {
            mode: 'index'
        }}}/>
    )
}

export default Graph;